import HomePage, { HomePageProps } from 'design-system/home-page/home-page';
import { graphql } from 'gatsby';
import React from 'react';

/**
 * This file just creates a static page at root (./index.html)
 * and we simply have it load our HomePage component
 */
const IndexPage: React.FC<HomePageProps> = (props: HomePageProps) => {
  return <HomePage data={props.data} />;
};

export const homePageQuery = graphql`
  query {
    shSpectrumHealthOrgGlobalNav {
      ...globalNavFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shSpectrumhealthOrgHomepage {
      ...homePageFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
  }
`;

export default IndexPage;
